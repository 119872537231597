
var noop = function noop() { };
var onOpen = noop;
var onClose = noop;
var onMessage = noop;
var onError = noop;

function listen(listener) {
    if (listener) {
        onOpen = listener.onOpen;
        onClose = listener.onClose;
        onMessage = listener.onMessage;
        onError = listener.onError;
    }
}

var currentClient = null;

var STATUS_CLOSED = SocketClient.STATUS_CLOSED = 'CLOSED';
var STATUS_CONNECTING = SocketClient.STATUS_CONNECTING = 'CONNECTING';
var STATUS_ACTIVE = SocketClient.STATUS_ACTIVE = 'ACTIVE';
var STATUS_RECONNECTING = SocketClient.STATUS_RECONNECTING = 'RECONNECTING';

// 包类型枚举
var PACKET_TYPE_MESSAGE = 'message';
var PACKET_TYPE_PING = 'ping';
var PACKET_TYPE_PONG = 'pong';
var PACKET_TYPE_TIMEOUT = 'timeout';
var PACKET_TYPE_CLOSE = 'close';

var DEFAULT_MAX_RECONNECT_TRY_TIMES = 5;
var DEFAULT_RECONNECT_TIME_INCREASE = 1000;

export function SocketClient(socketUrl) {
    if (currentClient && currentClient.status !== STATUS_CLOSED) {
        throw new Error("当前有未关闭的连接，请先关闭之前的连接，再打开新连接");
    }

    currentClient = this;

    var me = this;

    this.socketUrl = socketUrl;
    this.conn = null;
    this.status = null;

    this.open = openConnect;
    this.on = registerEventHandler;
    this.emit = emitMessagePacket;
    this.close = close;

    this.isClosed = isClosed;
    this.isConnecting = isConnecting;
    this.isActive = isActive;
    this.isReconnecting = isReconnecting;

    function isClosed() { return me.status === STATUS_CLOSED; }
    function isConnecting() { return me.status === STATUS_CONNECTING; }
    function isActive() { return me.status === STATUS_ACTIVE; }
    function isReconnecting() { return me.status === STATUS_RECONNECTING; }

    function setStatus(status) {
        var lastStatus = me.status;
        if (lastStatus !== status) {
            me.status = status;
        }
    }

    // 初始为关闭状态
    setStatus(STATUS_CLOSED);

    var preservedEventTypes = 'connect,close,reconnecting,reconnect,error'.split(',');
    var eventHandlers = [];

    /**
     * 注册消息处理函数
     * @param {string} messageType 支持内置消息类型（"connect"|"close"|"reconnecting"|"reconnect"|"error"）以及业务消息类型
     */
    function registerEventHandler(eventType, eventHandler) {
        if (typeof eventHandler === 'function') {
            eventHandlers.push([eventType, eventHandler]);
        }
    }

    /**
     * 派发事件，通知所有处理函数进行处理
     */
    function dispatchEvent(eventType, eventPayload) {
        eventHandlers.forEach(function (handler) {
            var handleType = handler[0];
            var handleFn = handler[1];

            if (handleType === '*') {
                handleFn(eventType, eventPayload);
            } else if (handleType === eventType) {
                handleFn(eventPayload);
            }
        });
    }

    /**
     * 派发事件，事件类型和系统保留冲突的，事件名会自动加上 '@' 前缀
     */
    function dispatchEscapedEvent(eventType, eventPayload) {
        if (preservedEventTypes.indexOf(eventType) > -1) {
            eventType = '@' + eventType;
        }

        dispatchEvent(eventType, eventPayload);
    }


    var isFirstConnection = true;
    var isOpening = false;

    function openConnect() {
        if (isOpening) return;
        isOpening = true;

        setStatus(isFirstConnection ? STATUS_CONNECTING : STATUS_RECONNECTING);

        openSocket();
    }

    function openSocket() {
        // listen({
        //     onOpen: handleSocketOpen,
        //     onMessage: handleSocketMessage,
        //     onClose: handleSocketClose,
        //     onError: handleSocketError,
        // });

        me.conn = new WebSocket(me.socketUrl);
        me.conn.onopen = handleSocketOpen;
        me.conn.onmessage = handleSocketMessage;
        me.conn.onclose = handleSocketClose;
        me.conn.onerror = handleSocketError;

        isOpening = false;
    }

    // websocket 回调
    function handleSocketOpen() {
        if (isConnecting()) {
            dispatchEvent("connect");
        } else if (isReconnecting()) {
            dispatchEvent("reconnect");
            resetReconnectionContext();
        }

        setStatus(STATUS_ACTIVE);
        // 发送队列未发送包
        emitQueuedPackets();
        nextPing();
    }

    function handleSocketMessage(message) {
        resolvePacket(message.data);
    }

    function handleSocketClose() {
        if (isClosing) return;

        if (isActive()) {
            startReconnect("链接已断开");
        }
    }

    function handleSocketError(detail) {
        switch (me.status) {
            case SocketClient.STATUS_CONNECTING:
                dispatchEvent("error", {
                    message: "连接Access失败，网络错误或者Access服务不可用",
                    detail: detail
                });
                break;
        }
    }

    // 关闭
    var isClosing = false;

    function close() {
        if (isClosed()) {
            return;
        }
        isClosing = true;
        closeSocket();
        setStatus(STATUS_CLOSED);
        resetReconnectionContext();
        isFirstConnection = false;
        clearTimeout(pingTimer);
        clearTimeout(pongTimer);
        clearTimeout(reconnectTimer);
        dispatchEvent("close");
        isClosing = false;
    }

    function closeSocket() {
        emitClosePacket();
        me.conn.close();
    }

    // 重连
    var reconnectTryTimes = 0;
    var waitBeforeReconnect = 0;

    var reconnectTimer = 0;

    function resetReconnectionContext() {
        reconnectTryTimes = 0;
        waitBeforeReconnect = 0;
    }

    function startReconnect(lastError) {
        if (reconnectTryTimes >= DEFAULT_MAX_RECONNECT_TRY_TIMES) {
            close();

            dispatchEvent("error", {
                message: "重连失败",
                detail: lastError
            });
        } else {
            me.conn.close();
            waitBeforeReconnect += DEFAULT_RECONNECT_TIME_INCREASE;
            setStatus(STATUS_RECONNECTING);
            reconnectTimer = setTimeout(doReconnect, waitBeforeReconnect);
        }

        if (reconnectTryTimes === 0) {
            dispatchEvent("reconnecting");
        }
        reconnectTryTimes += 1;
    }

    function doReconnect() {
        openConnect();
    }

    // 心跳
    var pingPongTimeout = 15000;
    var pingTimer = 0;
    var pongTimer = 0;

    function nextPing() {
        clearTimeout(pingTimer);
        clearTimeout(pongTimer);
        pingTimer = setTimeout(ping, pingPongTimeout);
    }

    function ping() {
        if (isActive()) {
            emitPingPacket();
            pongTimer = setTimeout(handlePongTimeout, pingPongTimeout);
        }
    }

    function handlePongTimeout() {
        // Pong超时，重连
        startReconnect("服务器已失去响应");
    }

    // 发送数据包
    // 连接还没成功建立的时候，需要发送的包会先存放到队列里
    var queuedPackets = [];

    function emitPacket(packet) {
        if (isActive()) {
            sendPacket(packet);
        } else {
            queuedPackets.push(packet);
        }
    }

    function sendPacket(packet) {
        var encodedPacket = [packet.type];

        if (packet.content) {
            encodedPacket.push(JSON.stringify(packet.content));
        }
        me.conn.send(encodedPacket.join(':'));
    }

    function emitQueuedPackets() {
        queuedPackets.forEach(emitPacket);
        queuedPackets.length = 0;
    }

    // 发送消息包
    function emitMessagePacket(messageType, messageContent) {
        var packet = {
            type: PACKET_TYPE_MESSAGE,
            content: {
                type: messageType,
                content: messageContent
            }
        };

        emitPacket(packet);
    }

    // 发送ping包
    function emitPingPacket() {
        emitPacket({ type: PACKET_TYPE_PING });
    }

    // 发送关闭包
    function emitClosePacket() {
        emitPacket({ type: PACKET_TYPE_CLOSE });
    }

    // 解析接收包
    function resolvePacket(raw) {
        var packetParts = raw.split(':');
        var packetType = packetParts.shift();
        var packetContent = packetParts.join(':') || null;
        var packet = { type: packetType };

        if (packetContent) {
            try {
                packet.content = JSON.parse(packetContent);
            } catch (e) {}
        }

        switch (packet.type) {
            case PACKET_TYPE_MESSAGE:
                handleMessagePacket(packet);
                break;
            case PACKET_TYPE_PONG:
                handlePongPacket(packet);
                break;
            case PACKET_TYPE_TIMEOUT:
                handleTimeoutPacket(packet);
                break;
            case PACKET_TYPE_CLOSE:
                handleClosePacket(packet);
                break;
            default:
                handleUnknownPacket(packet);
                break;
        }
    }

    function handleMessagePacket(packet) {
        var message = packet.content;
        dispatchEscapedEvent(message.type, message.content);
    }

    function handlePongPacket(packet) {
        nextPing();
    }

    function handleTimeoutPacket(packet) {
        var timeout = packet.content * 1000;
        /* istanbul ignore else */
        if (!isNaN(timeout)) {
            pingPongTimeout = timeout;
            ping();
        }
    }

    function handleClosePacket(packet) {
        close();
    }

    function handleUnknownPacket(packet) {
        // throw away
    }

    window.addEventListener('online', function () {
        doReconnect()
        console.log('网络已连接');
    });
    window.addEventListener('offline', function () {
        console.log('已断网');
    });
}