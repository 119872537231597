













import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  SocketClient
} from '@/utils/websocket'
import bus from '@/utils/bus'

const socketUrl: string = 'wss://gateway.hunlibaoapp.com/ws?type=EcScrmMsg&id=';
const keyName: string = 'wechat-h5-userinfo';

@Component
export default class App extends Vue {
  private websocket: any = null
  private adminId: number = 22

  private created() {
    let router:any = this.$router
    if (router.path !== '/login' && router.path !== '/' && router.path !== '/dialogue') {
      router.replace('/')
    }
  }

  private mounted() {
    this.initSocket()
  }

  /**
   * @func 初始化websocket
   */
  private initSocket() {
    this.websocket = new SocketClient(socketUrl + this.adminId);
    this.websocket.open();
    this.websocket.on('connect', () => {
      console.log('连接成功');
    });
    this.websocket.on('close', () => {
      console.log('连接已断开');
    });
    this.websocket.on('broadcast', (message: string) => {
      const data: any = JSON.parse(message);
      if (data.type === 7) {
        bus.$emit('NoticeBroadcast', JSON.parse(data.content));
      } else {
        bus.$emit('Boardcast', {msg: data})
      }
    });
  }
}
