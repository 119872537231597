import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/dialogue',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '聚合系统'
    }
  },
  {
    path: '/dialogue',
    name: 'Dialogue',
    component: () => import('@/views/dialogue/index.vue'),
    meta: {
      title: '会话列表'
    }
  },
  {
    path: '/address',
    name: 'Address',
    component: () => import('@/views/address-book/index.vue'),
    meta: {
      title: '通讯录'
    }
  },
  {
    path: '/member',
    name: 'Member',
    component: () => import('@/views/member/index.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/moment',
    name: 'Moment',
    component: () => import('@/views/moment/index.vue'),
    meta: {
      title: '朋友圈'
    }
  },
  {
    path: '/create-moment',
    name: 'CreateMoment',
    component: () => import('@/views/moment/create.vue'),
    meta: {
      title: '发朋友圈'
    }
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('@/views/mine/index.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import('@/views/chat/index.vue'),
    meta: {
      title: '聊天室'
    }
  },
  {
    path: '/friends',
    name: 'Friends',
    component: () => import('@/views/chat/select-friend.vue'),
    meta: {
      title: '选择好友'
    }
  },
  {
    path: '/select-dialogue',
    name: 'SelectDialogue',
    component: () => import('@/views/chat/select-dialogue.vue'),
    meta: {
      title: '选择会话'
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/search/search.vue'),
    meta: {
      title: '搜索'
    }
  },
  {
    path: '/chatroom',
    name: 'Chatroom',
    component: () => import('@/views/address-book/chatroom.vue'),
    meta: {
      title: '群聊'
    }
  },
  {
    path: '/new-friend',
    name: 'NewFriend',
    component: () => import('@/views/address-book/new-friend.vue'),
    meta: {
      title: '新好友'
    }
  },
  {
    path: '/material',
    name: 'Material',
    component: () => import('@/views/moment/material.vue'),
    meta: {
      title: '素材库'
    }
  },
  {
    path: '/frienddesc',
    name: 'FriendDesc',
    component: () => import('@/views/address-book/friend-desc.vue'),
    meta: {
      title: '好友详情'
    }
  },
  {
    path: '/remark',
    name: 'Remark',
    component: () => import('@/views/address-book/remark-and-tag.vue'),
    meta: {
      title: '设置备注'
    }
  },
  {
    path: '/friend-moment',
    name: 'FriendMoment',
    component: () => import('@/views/moment/friend-moment.vue'),
    meta: {
      title: '朋友圈'
    }
  },
  {
    path: '/set-tag',
    name: 'SetTag',
    component: () => import('@/views/address-book/set-tag.vue'),
    meta:{
      title: '添加标签'
    }
  }
]

const router = new VueRouter({
  routes
})

router.afterEach((to: Route, form: Route) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
})

export default router
