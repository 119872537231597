import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/style/index.less'
// import 'amfe-flexible'
import weui from 'weui.js'
import 'weui'
import store from '@/store'
// import fastclick from 'fastclick'
import '@/permission'
import gallery from 'img-vuer'
import VueTouch from 'vue-touch'
import vConsole from '@/utils/vconsole'
import vueMiniPlayer from 'vue-mini-player'
import 'vue-mini-player/lib/vue-mini-player.css'

// if ('addEventListener' in document) {
// 	document.addEventListener('DOMContentLoaded', function() {
//     fastclick.attach(document.body);
//   }, false);
// }

Vue.prototype.$weui = weui

Vue.config.productionTip = false

Vue.use(VueTouch, { name: 'v-touch' })
Vue.use(gallery, {
  swipeThreshold: 150, // default 100
  isIndexShow: true, // show image index, default true
  useCloseButton: false, // trigger gallery close with close button, default true
  preload: true, // preload images in the same group, default true
  sliderBackgroundColor: 'rgba(0,0,0,0.6)',
  sliderZIndex: 1101,
})
Vue.use(vueMiniPlayer)

// 调试
// vConsole

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
