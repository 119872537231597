import Cookies from 'js-cookie';

const TokenKey = 'REAL_WECHAT_H5_TOKEN';
const UserInfoKey = 'WECHAT_H5_LOGIN_USER-INFO';

export const getToken = () => Cookies.get(TokenKey);

export const setToken = (token: string) => Cookies.set(TokenKey, token);

export const removeToken = () => Cookies.remove(TokenKey);

// 获取管理员信息
export const getUserInfo = () => Cookies.get(UserInfoKey);

export const setUserInfo = (data: string) => Cookies.set(UserInfoKey, data);

export const removeUserInfo = () => Cookies.remove(UserInfoKey);

// 通用cookie方法
export const getComToken = (key: string) => Cookies.get(key);

export const setComToken = (key: string, data: string) => Cookies.set(key, data);

export const removeComToken = (key: string) => Cookies.remove(key);

// 通过localStorage缓存数据

export const setLocalStorage = (key: string, data: string) => localStorage.setItem(key, data);

export const getLocalStorage = (key: string) => localStorage.getItem(key);

export const removeLocalStorage = (key: string) => localStorage.removeItem(key);

export function getPkToken() {
    const userInfo = getUserInfo();
    if (userInfo) {
        const user = JSON.parse(userInfo);
        return user.pk;
    }
    return '';
}

export function getUserID() {
    const userInfo = getUserInfo();
    if (userInfo) {
        const user = JSON.parse(userInfo);
        return user.id;
    }
    return '';
}
